// 编辑期初
// popEditQichu
<template>

    <div class="popEditQichu">
        <el-dialog :title="`编辑${qichuType}`"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   width="850"
                   :before-close="close">
            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     label-position="top">
                <el-row :gutter="60">
                    <template v-if="qichuType.indexOf('合同') !== -1">
                        <el-col :span="8">
                            <el-form-item label="合同编号"
                                          prop="code">
                                <el-input v-model="form.code"
                                          placeholder="请输入合同编号"
                                          clearable></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="签订日期"
                                          prop="signDate">
                                <el-date-picker v-model="form.signDate"
                                                type="date"
                                                value-format="yyyy-MM-dd"
                                                placeholder="选择签订日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </template>
                    <template v-else>
                        <el-col :span="8">
                            <el-form-item label="其他收入编号"
                                          prop="code">
                                <el-input v-model="form.code"
                                          placeholder="请输入其他收入编号"
                                          clearable></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="日期"
                                          prop="occurDate">
                                <el-date-picker v-model="form.occurDate"
                                                type="date"
                                                value-format="yyyy-MM-dd"
                                                placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </template>
                    <el-col :span="8">
                        <el-form-item label="税率（%）"
                                      prop="taxRate">
                            <el-input placeholder="税率"
                                      type="number"
                                      min="0"
                                      v-model="form.taxRate">
                                <div slot="append">
                                    <span>%</span>
                                </div>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <template v-if="qichuType.indexOf('支出') !== -1">
                        <el-col :span="8">
                            <!-- 供应商 -->
                            <el-form-item label="供应商"
                                          prop="companyId">
                                <customer-or-provider-select-tree ref="selectProvider"
                                                                  type="PROVIDER"
                                                                  :shouldClean="true"
                                                                  :checkedInfo="{label: form.companyName, value: form.companyId}"
                                                                  @handClear="handClearProvider"
                                                                  @treeSelectNodeClick="selectProviderClick"></customer-or-provider-select-tree>
                            </el-form-item>
                        </el-col>
                    </template>
                    <template v-else>
                        <el-col :span="8">
                            <!-- 客户 -->
                            <el-form-item label="客户"
                                          prop="companyId">
                                <customer-or-provider-select-tree ref="selectTree"
                                                                  type="CUSTOMER"
                                                                  :shouldClean="true"
                                                                  :checkedInfo="{label: form.companyName, value: form.companyId}"
                                                                  @handClear="handClear"
                                                                  @treeSelectNodeClick="selectTreeCustClick"></customer-or-provider-select-tree>
                            </el-form-item>
                        </el-col>
                    </template>
                    <el-col :span="8">
                        <el-form-item label="项目"
                                      prop="projectId">
                            <el-select v-model="form.projectId"
                                       placeholder="请选择项目"
                                       popper-class="optsBox"
                                       clearable>
                                <el-option v-for="item in projectOpt"
                                           :key="item.code"
                                           :label="item.label"
                                           :value="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8"
                            v-if="qichuType.indexOf('其他') !== -1">
                        <el-form-item label="合计金额（元）"
                                      prop="amount">
                            <el-input-money placeholder="选择合计金额"
                                            v-model="form.amount"></el-input-money>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8"
                            v-else>
                        <el-form-item label="合同合计金额（元）"
                                      prop="amountTotal">
                            <el-input-money placeholder="选择合同合计金额"
                                            v-model="form.amountTotal"></el-input-money>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="收款余额（元）"
                                      prop="balance">
                            <el-input-money placeholder="选择收款余额"
                                            v-model="form.balance"></el-input-money>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="待开票金额（元）"
                                      prop="invoiceBalance">
                            <el-input-money placeholder="选择待开票金额"
                                            v-model="form.invoiceBalance"></el-input-money>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="备注"
                                      prop="remark">
                            <el-input v-model="form.remark"
                                      type="textarea"
                                      rows="5"
                                      placeholder="请输入备注"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="save">保存</el-button>
                </div>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
import ElInputMoney from "../../../components/form/elInputMoney";
import CustomerOrProviderSelectTree from "../../../components/selectTree/customerOrProviderSelectTree";
import { Loading } from "element-ui";
import API from "@/api/finance.js";
import selectOpts from "@/api/selectOpts.js";
export default {
    name: "popEditQichu",

    props: {
        // 查询详情用id
        id: {
            type: String,
        },
        // 期初类型
        qichuType: {
            type: String,
            default: "收入合同期初",
        },
    },

    components: {
        ElInputMoney,
        CustomerOrProviderSelectTree,
    },

    data() {
        return {
            dialogVisible: true,
            form: {
                code: "", // 合同编号 | 其他收入编号
                signDate: "", // 签订日期
                taxRate: "", // 税率
                companyId: "", // 客户 | 供应商
                projectId: "", // 项目
                amountTotal: "", // 合同合计金额
                balance: "", // 收款余额
                invoiceAmount: "", // 待开票金额
                remark: "", // 备注
                occurDate: "", // 日期
            },
            formRules: {
                code: {
                    required: true,
                    message: "请验证合同编号",
                    trigger: "blur",
                }, // 合同编号
                signDate: {
                    required: true,
                    message: "请验证签订日期",
                    trigger: "blur",
                }, // 签订日期
                taxRate: {
                    required: true,
                    message: "请验证税率",
                    trigger: "blur",
                }, // 税率
                companyId: {
                    required: true,
                    message: "请验证客户",
                    trigger: "blur",
                }, // 客户
                projectId: {
                    required: true,
                    message: "请验证项目",
                    trigger: "blur",
                }, // 项目
                balance: {
                    required: true,
                    message: "请验证收款余额",
                    trigger: "blur",
                }, // 收款余额
                invoiceAmount: {
                    required: true,
                    message: "请验证待开票金额",
                    trigger: "blur",
                }, // 待开票金额
                occurDate: {
                    required: true,
                    message: "请验证日期",
                    trigger: "blur",
                }, // 日期
            },
            projectOpt: [], //项目列表下拉
        };
    },

    created() {},

    mounted() {
        // 获取详情
        this.getQichuDetails();
        /* 获取项目 */
        this.getProjectOpts();
    },

    methods: {
        // 编辑期初保存
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let loading = Loading.service({
                        target: document.querySelector(".el-dialog"),
                    });

                    if (this.qichuType.indexOf("合同") !== -1) {
                        // 合同
                        API.saveQichu(this.form)
                            .then(() => {
                                loading.close();
                                this.$message({
                                    message: "操作成功！",
                                    type: "success",
                                });
                                this.$emit("update");
                                this.close();
                            })
                            .catch(() => {
                                loading.close();
                            });
                    } else {
                        // 其他收入支出
                        API.saveOtherQichu(this.form)
                            .then(() => {
                                loading.close();
                                this.$message({
                                    message: "操作成功！",
                                    type: "success",
                                });
                                this.$emit("update");
                                this.close();
                            })
                            .catch(() => {
                                loading.close();
                            });
                    }
                }
            });
        },
        // 获取详情
        getQichuDetails() {
            let loading = Loading.service({
                target: document.querySelector(".el-dialog"),
            });
            if (this.qichuType.indexOf("合同") !== -1) {
                // 合同
                API.getQichuDetails(this.id)
                    .then((res) => {
                        loading.close();
                        this.form = res.content;
                    })
                    .catch(() => {
                        loading.close();
                    });
            } else {
                // 其他收入支出
                API.getOtherQichuDetails(this.id)
                    .then((res) => {
                        loading.close();
                        this.form = res.content;
                    })
                    .catch(() => {
                        loading.close();
                    });
            }
        },
        // 获取项目下拉列表
        getProjectOpts() {
            let loading = Loading.service({
                target: document.querySelector(".optsBox"),
            });
            selectOpts
                .getProjectListBystatus({ size: 9999, status: [1, 2] })
                .then((res) => {
                    loading.close();
                    this.projectOpt = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 客户的选择
        selectTreeCustClick(node) {
            this.form.companyId = node.id;
        },
        // 客户的清空
        handClear() {
            this.form.companyId = "";
        },
        // 供应商的选择
        selectProviderClick(node) {
            this.form.companyId = node.id;
        },
        // 供应商的清空
        handClearProvider() {
            this.form.companyId = "";
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>