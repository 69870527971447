// 期初
<template>

    <div class="qichu baseBg">
        <div class="listBox mainWhiteBg">
            <div class="tableBox">
                <div class="greySearchBar">
                    <el-form :model="searchForm"
                             ref="searchForm"
                             :rules="searchFormRules"
                             :inline="true">
                        <el-form-item :label="qichuType.indexOf('其他')!==-1 ? qichuType.slice(2,4)+'编号' : '合同编号'"
                                      prop="code">
                            <el-input v-model="searchForm.code"
                                      placeholder="请输入合同编号"
                                      clearable></el-input>
                        </el-form-item>
                        <el-form-item label="客户"
                                      prop="companyId">
                            <customer-or-provider-select-tree ref="selectTree"
                                                              type="CUSTOMER"
                                                              :shouldClean="shouldClean"
                                                              @handClear="handClear"
                                                              @treeSelectNodeClick="selectTreeCustClick"></customer-or-provider-select-tree>
                        </el-form-item>
                        <el-form-item label="项目"
                                      prop="projectId">
                            <com-select-item-for-keyword v-model="searchForm.projectId"></com-select-item-for-keyword>
                        </el-form-item>
                        <el-button type="primary"
                                   @click="getList()">搜索</el-button>
                        <el-button @click="resetSearchForm">清除</el-button>
                    </el-form>
                </div>

                <!-- 控制条 -->
                <div class="greySearchBar control">
                    <com-export-table-to-excel :menuId="menuId"
                                               :searchForm="searchForm"></com-export-table-to-excel>
                    <el-button type="success"
                               class="flr"
                               @click="openpopImportQichu">导入{{qichuType}}</el-button>
                </div>

                <div class="tableBox">
                    <el-table :data="tableData.data"
                              border
                              :height="tableHeight"
                              stripe>
                        <el-table-column type="index"
                                         label="序号"
                                         width="60">
                        </el-table-column>
                        <el-table-column prop="code"
                                         :label="qichuType.indexOf('其他')!==-1 ? qichuType.slice(0,4)+'编号' : '合同编号'"
                                         width="155"
                                         :min-width="auto">
                        </el-table-column>
                        <el-table-column prop="companyName"
                                         label="客户"
                                         width="auto"
                                         :min-width="100">
                        </el-table-column>
                        <el-table-column prop="projectName"
                                         label="项目"
                                         width="auto"
                                         :min-width="100">
                        </el-table-column>
                        <el-table-column prop="date"
                                         :label="qichuType.indexOf('其他')!==-1 ? '日期' : '签订日期'"
                                         width="114"
                                         :min-width="114">
                        </el-table-column>
                        <el-table-column prop="taxRate"
                                         label="税率（%）"
                                         width="auto"
                                         :min-width="100">
                        </el-table-column>
                        <el-table-column prop="amount"
                                         label="合计金额（元）"
                                         width="auto"
                                         :min-width="100">
                        </el-table-column>
                        <el-table-column prop="balance"
                                         :label="qichuType.indexOf('收入') !== -1 ? '收款余额（元）' : '付款余额（元）'"
                                         width="auto"
                                         :min-width="100">
                        </el-table-column>
                        <el-table-column prop="invoiceBalance"
                                         :label="qichuType.indexOf('收入') !== -1 ? '待开票金额（元）' : '待收票金额（元）'"
                                         width="auto"
                                         :min-width="100">
                        </el-table-column>
                        <el-table-column prop="remark"
                                         label="备注"
                                         width="auto"
                                         :min-width="100">
                        </el-table-column>
                        <el-table-column prop="status"
                                         :label="`对应${qichuType.slice(0,4)}状态`"
                                         width="auto"
                                         :min-width="100">
                        </el-table-column>
                        <el-table-column prop="id"
                                         label="操作"
                                         align="left"
                                         width="100">
                            <template slot-scope="scope"
                                      v-if="scope.row.status.text === '草稿' || scope.row.status.text === '驳回'">
                                <el-button type="primary"
                                           size="mini"
                                           @click="openpopEditQichu(scope.row)">编辑</el-button>
                                <el-popconfirm confirm-button-text="删除"
                                               cancel-button-text="取消"
                                               icon="el-icon-info"
                                               icon-color="red"
                                               @confirm="del(scope.row.id)"
                                               title="真的要删除吗？">
                                    <el-button type="danger"
                                               size="mini"
                                               slot="reference">删除</el-button>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="tableStatisticsBar"
                         v-if="qichuAmount">
                        <span>期初总金额：{{commonJs.toThousands(qichuAmount.totalAmount)}}</span>
                        <span>{{qichuType.indexOf("收入") !== -1 ? "期初已收款金额" : "期初已付款金额"}}：{{commonJs.toThousands(qichuAmount.receiptPaidAmount)}}</span>
                        <span>{{qichuType.indexOf("收入") !== -1 ? "应收余额" : "应付余额"}}：{{commonJs.toThousands(qichuAmount.balance)}}</span>
                        <span>{{qichuType.indexOf("收入") !== -1 ? "期初已开票金额" : "期初已收票金额"}}：{{commonJs.toThousands(qichuAmount.invoiceAmount)}}</span>
                    </div>
                    <!-- 分页 -->
                    <Pagination :page="searchForm.page"
                                :limit="searchForm.size"
                                :total="pageTotal"
                                @pagination="paginationChange" />

                </div>

            </div>
        </div>

        <!-- 编辑期初 -->
        <pop-edit-qichu v-if="popEditQichuVisible"
                        :qichuType="qichuType"
                        :id="editRow.id"
                        @update="getList()"
                        @close="closepopEditQichu"></pop-edit-qichu>
        <!-- 导入期初 -->
        <pop-import-qichu v-if="popImportQichuVisible"
                          :type="qichuType"
                          @update="getList()"
                          @close="closepopImportQichu"></pop-import-qichu>
    </div>

</template>

<script>
import ComExportTableToExcel from "../../components/export/comExportTableToExcel";
import PopImportQichu from "./components/popImportQichu";
import PopEditQichu from "./components/popEditQichu";
import ComSelectItemForKeyword from "../../modleComponents/project/comSelectItemForKeyword";
import CustomerOrProviderSelectTree from "../../components/selectTree/customerOrProviderSelectTree";
import Pagination from "@/components/Pagination"; // 分页
import { Loading } from "element-ui";
import API from "@/api/finance.js";

export default {
    name: "qichu",

    props: {},

    components: {
        ComExportTableToExcel,
        PopImportQichu,
        PopEditQichu,
        ComSelectItemForKeyword,
        CustomerOrProviderSelectTree,
        Pagination,
    },

    data() {
        return {
            searchForm: {
                code: "", // 单号
                type: "INCOME_CONTRACT", // 类型，枚举 【PAY_CONTRACT('支出'),INCOME_CONTRACT('收入'),OTHER_PAY('其他支出'), INCOME_OTHER('其他收入')】
                page: 1,
                size: 20,
            },
            searchFormRules: {},
            pageTotal: 0,
            tableData: {
                data: [],
            },
            popEditQichuVisible: false, // 编辑期初弹窗显示
            popImportQichuVisible: false, // 导入期初弹窗显示
            editRow: null, // 当前操作行
        };
    },

    created() {},

    mounted() {
        let type = null;
        switch (this.qichuType) {
            case "收入合同期初":
                type = "INCOME_CONTRACT";
                break;
            case "其他收入期初":
                type = "INCOME_OTHER";
                break;
            case "支出合同期初":
                type = "PAY_CONTRACT";
                break;
            case "其他支出期初":
                type = "OTHER_PAY";
                break;
            default:
                break;
        }
        this.searchForm.type = type;
        // 获取列表
        this.getList();
    },

    methods: {
        // 获取列表
        getList(page) {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            let data = Object.assign({}, this.searchForm, {
                page: page || this.searchForm.page,
            });
            if (this.qichuType.indexOf("合同") !== -1) {
                // 合同
                API.getContractQichuList(data, this.menuId)
                    .then((res) => {
                        loading.close();
                        this.tableData.data = res.content.records;
                        this.pageTotal = res.content.total;
                    })
                    .catch(() => {
                        loading.close();
                    });
                // 获取期初金额统计
                this.getQichuAmount(
                    this.searchForm.type,
                    "getQichuContractAmount"
                );
            } else {
                // 其他收入支出
                API.getOtherQichuList(data, this.menuId)
                    .then((res) => {
                        loading.close();
                        this.tableData.data = res.content.records;
                        this.pageTotal = res.content.total;
                    })
                    .catch(() => {
                        loading.close();
                    });
                // 获取期初金额统计
                this.getQichuAmount(
                    this.searchForm.type,
                    "getQichuOtherAmount"
                );
            }
        },
        // 获取期初金额统计
        getQichuAmount(type, api) {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API[api](type)
                .then((res) => {
                    loading.close();
                    this.qichuAmount = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 分页改变
        paginationChange(e) {
            this.searchForm.size = e.size;
            this.searchForm.page = e.page;
            // 获取列表
            this.getList();
        },
        // 删除期初
        del(id) {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            if (this.qichuType.indexOf("合同") !== -1) {
                // 合同
                API.delQichu(id)
                    .then(() => {
                        loading.close();
                        this.$message({
                            message: "操作成功！",
                            type: "success",
                        });
                        this.getList();
                        this.close();
                    })
                    .catch(() => {
                        loading.close();
                    });
            } else {
                // 其他收入支出
                API.delOtherQichu(id)
                    .then(() => {
                        loading.close();
                        this.$message({
                            message: "操作成功！",
                            type: "success",
                        });
                        this.getList();
                        this.close();
                    })
                    .catch(() => {
                        loading.close();
                    });
            }
        },
        // 客户的选择
        selectTreeCustClick(node) {
            this.searchForm.companyId = node.id;
        },
        // 客户的清空
        handClear() {
            this.searchForm.companyId = "";
            this.shouldClean = Math.random();
        },
        // 清空表单
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    this.handClear();
                    // 查询列表
                    this.getList();
                }
            });
        },
        // 编辑期初 打开弹框
        openpopEditQichu(row) {
            this.editRow = row || null;
            this.popEditQichuVisible = true;
        },
        // 编辑期初 关闭弹框
        closepopEditQichu() {
            this.editRow = null;
            this.popEditQichuVisible = false;
        },
        // 导入期初 打开弹框
        openpopImportQichu() {
            this.popImportQichuVisible = true;
        },
        // 导入期初 关闭弹框
        closepopImportQichu() {
            this.popImportQichuVisible = false;
        },
    },

    computed: {
        // 根据路由判断期初类型
        qichuType() {
            let type = "收入合同期初";
            switch (this.$route.name) {
                case "incomeQichu":
                    type = "收入合同期初";
                    break;
                case "otherIncomeQichu":
                    type = "其他收入期初";
                    break;
                case "payQichu":
                    type = "支出合同期初";
                    break;
                case "otherPayQichu":
                    type = "其他支出期初";
                    break;
                default:
                    break;
            }
            return type;
        },
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 325 });
        },
        menuId() {
            if (this.qichuType.indexOf("合同") !== -1) {
                // 合同
                return this.qichuType === "收入合同期初"
                    ? "1423171920762814465"
                    : "1423172686361706497";
            } else {
                // 其他收入支出
                return this.qichuType === "其他收入期初"
                    ? "1423172564437483522"
                    : "1423172782637760514";
            }
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
.pagination-container {
    margin-top: 0;
}
</style>
